import PropTypes from 'prop-types';
import React, { useMemo, useState, useEffect } from 'react';
import get from 'lodash/get';
import { Link, navigate, useStaticQuery, graphql } from 'gatsby';

import isEmpty from 'lodash/isEmpty';
import Dropdown from '../../global/Dropdown';
import Container from '../../global/Container';
import Icon from '../../global/Icon';
import {
    BigMenu,
    ButtonLinkContainer,
    ButtonRight,
    FixedDiv,
    HamburgerMenuContainer,
    HeaderContent,
    HeaderLink,
    HeaderWrapper,
    Highlight,
    Links,
    Logo,
    SmallMenu,
    StyledButton,
    StyledLink,
} from './styled';
import Collapse from '../../global/Collapse';
import Button from '../../global/ButtonLink';

import { getLabel, getIconSources } from './helpers';
import { localiseData, preloadImages } from '../../../utils/helpers';
import switchLanguage from '../../../utils/switchLanguage';

const Header = ({ path, lng, minimal }) => {
    const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);

    useEffect(() => {
        if (isHamburgerMenuOpen) {
            window.document.body.style.overflowY = 'hidden';
        } else {
            window.document.body.style.overflowY = 'auto';
        }
    }, [isHamburgerMenuOpen]);

    function handleOnItemClick(headerLink, item) {
        if (headerLink.isLanguageSwitcher) {
            switchLanguage(item.value, path, lng);
        } else {
            navigate(`/${lng}${item.value}`);
        }

        setIsHamburgerMenuOpen(false);
    }

    const pageQuery = useStaticQuery(graphql`
        query HeaderQuery {
            strapiHeader {
                logoButton {
                    href
                    logo {
                        publicURL
                    }
                }
                header_links {
                    id
                    title_en
                    title_de
                    link {
                        iconDefault {
                            publicURL
                        }
                        iconHover {
                            publicURL
                        }
                        label_en
                        label_de
                        value
                        description_en
                        description_de
                        id
                    }
                    href
                    isLanguageSwitcher
                }
                button {
                    href
                    label_en
                    label_de
                }
            }
        }
    `);

    const { strapiHeader: data } = useMemo(() => localiseData(pageQuery, lng), [lng, pageQuery]);

    const iconsSources = useMemo(() => getIconSources(data), [data]);

    useEffect(() => {
        if (!isEmpty(iconsSources)) {
            preloadImages(iconsSources);
        }
    }, [iconsSources]);

    const logoContent = (
        <div>
            <Link
                to={`/${lng}${data.logoButton.href}`}
                onClick={() => setIsHamburgerMenuOpen(false)}
            >
                <Logo src={data.logoButton.logo.publicURL} alt="RelevanceOne logo" />
            </Link>
        </div>
    );

    if (minimal) {
        return (
            <FixedDiv>
                <HeaderWrapper>
                    <Container>
                        <HeaderContent minimal>{logoContent}</HeaderContent>
                    </Container>
                </HeaderWrapper>
            </FixedDiv>
        );
    }

    return (
        <>
            <FixedDiv>
                <BigMenu>
                    <HeaderWrapper>
                        <Container>
                            <HeaderContent>
                                {logoContent}
                                <Links>
                                    {data.header_links.map((headerLink, index) => (
                                        <ButtonLinkContainer key={headerLink.id}>
                                            {index === data.header_links.length - 2 ? (
                                                <Button
                                                    href={data.button.href}
                                                    lng={lng}
                                                    label={data.button.label}
                                                />
                                            ) : null}
                                            <HeaderLink>
                                                {headerLink.link.length > 0 ? (
                                                    <Dropdown
                                                        buttonPaddding="0"
                                                        key={headerLink.id}
                                                        items={headerLink.link.map(link => ({
                                                            value: link.value,
                                                            label: link.label,
                                                            description: link.description,
                                                            iconDefault: link.iconDefault
                                                                ? link.iconDefault.publicURL
                                                                : undefined,
                                                            iconHover: link.iconHover
                                                                ? link.iconHover.publicURL
                                                                : undefined,
                                                        }))}
                                                        id={headerLink.id}
                                                        width={
                                                            headerLink.link[0].iconDefault
                                                                ? '450px'
                                                                : undefined
                                                        }
                                                        onItemClick={({ item }) =>
                                                            handleOnItemClick(headerLink, item)
                                                        }
                                                    >
                                                        {headerLink.title || ''}
                                                        {headerLink.isLanguageSwitcher
                                                            ? get(
                                                                  headerLink.link.find(
                                                                      link => link.value === lng
                                                                  ),
                                                                  'label',
                                                                  'En'
                                                              )
                                                            : ''}
                                                    </Dropdown>
                                                ) : (
                                                    <StyledLink key={headerLink.id}>
                                                        {headerLink.href.startsWith('/') ? (
                                                            <Link to={`/${lng}${headerLink.href}`}>
                                                                {headerLink.title}
                                                            </Link>
                                                        ) : (
                                                            <a
                                                                href={headerLink.href}
                                                                target="_blank"
                                                                rel="nofollow noreferrer"
                                                            >
                                                                {headerLink.title}
                                                            </a>
                                                        )}
                                                    </StyledLink>
                                                )}
                                            </HeaderLink>
                                        </ButtonLinkContainer>
                                    ))}
                                </Links>
                            </HeaderContent>
                        </Container>
                    </HeaderWrapper>
                </BigMenu>
            </FixedDiv>
            <SmallMenu>
                <HeaderContent hasPadding hasBottomBorder>
                    <div>
                        <Link
                            to={`/${lng}${data.logoButton.href}`}
                            onClick={() => setIsHamburgerMenuOpen(false)}
                        >
                            <Logo
                                src={data.logoButton.logo.publicURL}
                                small
                                alt="RelevanceOne logo"
                            />
                        </Link>
                    </div>
                    <ButtonRight>
                        <Button
                            href={data.button.href}
                            lng={lng}
                            label={data.button.label}
                            showonbig={1}
                        />
                    </ButtonRight>
                    <StyledButton
                        type="button"
                        onClick={() => setIsHamburgerMenuOpen(!isHamburgerMenuOpen)}
                    >
                        <Icon name={isHamburgerMenuOpen ? 'close' : 'menu'} />
                    </StyledButton>
                </HeaderContent>
                {isHamburgerMenuOpen && (
                    <HamburgerMenuContainer>
                        <Button
                            href={data.button.href}
                            lng={lng}
                            label={data.button.label}
                            showonsmall={1}
                            highlighted
                        />
                        {data.header_links.map(headerLink => (
                            <li key={headerLink.id}>
                                {headerLink.link.length > 0 ? (
                                    <Collapse
                                        key={headerLink.id}
                                        items={headerLink.link.map(link => ({
                                            value: link.value,
                                            label: link.label,
                                            description: link.description,
                                            iconDefault: link.iconDefault
                                                ? link.iconDefault.publicURL
                                                : undefined,
                                            iconHover: link.iconHover
                                                ? link.iconHover.publicURL
                                                : undefined,
                                        }))}
                                        onItemClick={({ item }) =>
                                            handleOnItemClick(headerLink, item)
                                        }
                                        label={getLabel(headerLink, lng)}
                                    />
                                ) : (
                                    <>
                                        {headerLink.href.startsWith('/') ? (
                                            <Highlight
                                                role="button"
                                                onClick={() => setIsHamburgerMenuOpen(false)}
                                            >
                                                <span key={headerLink.id}>
                                                    <Link to={`/${lng}${headerLink.href}`}>
                                                        {headerLink.title}
                                                    </Link>
                                                </span>
                                            </Highlight>
                                        ) : (
                                            <Highlight
                                                role="button"
                                                onClick={() => setIsHamburgerMenuOpen(false)}
                                            >
                                                <a
                                                    href={headerLink.href}
                                                    target="_blank"
                                                    rel="noreferrer nofollow"
                                                >
                                                    {headerLink.title}
                                                </a>
                                            </Highlight>
                                        )}
                                    </>
                                )}
                            </li>
                        ))}
                    </HamburgerMenuContainer>
                )}
            </SmallMenu>
        </>
    );
};

Header.propTypes = {
    lng: PropTypes.string.isRequired,
    minimal: PropTypes.bool.isRequired,
    path: PropTypes.string.isRequired,
};

export default Header;
