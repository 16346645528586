import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// components
import {
    ButtonDescription,
    ButtonText,
    ImageDiv,
    List,
    ArrowRight,
    StyledButtonWithPadding,
} from '../styled';

const ListContent = ({
    show,
    width,
    position,
    dropUp,
    height,
    items,
    handleOnItemClick,
    selected,
}) => {
    const searchInputRef = useRef();

    useEffect(() => {
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, []);

    return (
        <List show={show} width={width} position={position} dropUp={dropUp} height={height}>
            {items.map((item, index) => (
                <li key={item.value}>
                    <StyledButtonWithPadding
                        onClick={event => handleOnItemClick({ item, event, index })}
                        selected={selected === item.value}
                        type="button"
                        src={item.iconDefault}
                        srcHover={item.iconHover}
                    >
                        {item.iconDefault && item.iconHover ? (
                            <ImageDiv>
                                <span />
                            </ImageDiv>
                        ) : null}
                        <div>
                            <ButtonText>{item.label}</ButtonText>
                            {item.description ? (
                                <ButtonDescription>{item.description}</ButtonDescription>
                            ) : null}
                        </div>
                        {item.iconDefault && item.iconHover ? (
                            <ArrowRight name="arrow_right_alt" />
                        ) : null}
                    </StyledButtonWithPadding>
                </li>
            ))}
        </List>
    );
};

ListContent.defaultProps = {
    dropUp: false,
    height: '400px',
    selected: '',
    handleOnItemClick: () => {},
};

ListContent.propTypes = {
    dropUp: PropTypes.bool,
    height: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        })
    ).isRequired,
    handleOnItemClick: PropTypes.func,
    position: PropTypes.string.isRequired,
    selected: PropTypes.number,
    width: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
};

export default ListContent;
