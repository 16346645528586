import styled, { css } from 'styled-components';

export const Heading1 = styled.h1`
    font-size: 2.5rem;
    font-weight: 600;
    color: ${props => (props.white ? '#ffffff' : '#000000')};
    margin-bottom: ${({ marginBottom }) => marginBottom || 0};

    @media all and (max-width: 768px) {
        font-size: 1.75rem;
    }
`;

export const Heading2 = styled.h2`
    font-size: 2.5rem;
    font-weight: 600;
    color: #000000;
    margin-bottom: ${({ marginBottom }) => marginBottom || 0};
    margin-right: ${({ marginRight }) => marginRight || 0};
    ${props => (props.bold ? 'font-weight: 600;' : '')}
    ${({ center }) =>
        center &&
        css`
            text-align: center;
        `}

    @media all and (max-width: 768px) {
        font-size: 1.125rem;

        ${({ big }) =>
            big &&
            css`
                font-size: 1.5rem;
            `}
    }
`;

export const Heading3 = styled.h3`
    font-size: 1.875rem;
    font-weight: 600;
    color: #000000;
    margin-bottom: ${({ marginBottom }) => marginBottom || 0};
    margin-right: ${({ marginRight }) => marginRight || 0};
    ${props => (props.bold ? 'font-weight: 600;' : '')}

    @media all and (max-width: 768px) {
        font-size: 1.125rem;
    }
`;
export const Heading4 = styled.h4`
    font-size: 1.25rem;
    font-weight: 600;
    color: #000000;
    margin-bottom: ${({ marginBottom }) => marginBottom || 0};

    @media all and (max-width: 768px) {
        font-size: 1.125rem;
    }
`;

export const Heading5 = styled.h5`
    font-size: 1rem;
    font-weight: 500;
    color: #000000;
    margin-bottom: ${({ marginBottom }) => marginBottom || 0};
    ${({ bold }) =>
        bold &&
        css`
            font-weight: 600;
        `}
`;

export const Heading6 = styled.h6`
    font-size: 0.875rem;
    margin-bottom: ${({ marginBottom }) => marginBottom || 0};
    color: rgba(0, 0, 0, 0.54);
    font-weight: 500;
`;

export const Paragraph = styled.p`
    font-size: ${({ small }) => (small ? '0.875rem' : '1rem')};
    line-height: 2rem;
    color: ${props => {
        if (props.white) {
            return '#ffffff';
        }
        if (props.small) {
            return 'rgba(255,255,255,0.72)';
        }

        return 'rgba(0, 0, 0, 0.64)';
    }};
    ${({ bold }) =>
        bold &&
        css`
            font-weight: 600;
        `}
    ${({ center }) =>
        center &&
        css`
            text-align: center;
        `}
    ${({ left }) =>
        left &&
        css`
            text-align: left;
        `}
    margin-bottom: ${({ marginBottom }) => marginBottom || 0};
    ${({ margin }) =>
        margin &&
        css`
            margin: ${margin};
        `}

    @media all and (max-width: 768px) {
        font-size: 0.875rem;
        line-height: 24px;
        margin-bottom: ${({ marginbottomonsmall }) => marginbottomonsmall || 0};
    }
`;
