import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

const Icon = ({ name, style, className, outlined }) => (
    <i
        style={style}
        className={cn(
            outlined ? 'material-icons-outlined' : 'material-icons',
            className,
            'notranslate'
        )}
    >
        {name}
    </i>
);

Icon.defaultProps = {
    outlined: true,
    className: null,
    style: null,
};

Icon.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    outlined: PropTypes.bool,
    style: PropTypes.shape({}),
};

export default Icon;
