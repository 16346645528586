export function getIconSources(data) {
    return data.site_links.reduce((result, link) => {
        const sources = [];

        if (link.iconDefault) {
            sources.push(link.iconDefault.publicURL);
        }

        if (link.iconHover) {
            sources.push(link.iconHover.publicURL);
        }

        return [...result, ...sources];
    }, []);
}
