import { createGlobalStyle } from 'styled-components';

import 'react-simple-flex-grid/lib/main.css';

const GlobalStyle = createGlobalStyle`
  * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        color: rgba(0,0,0,0.87);
        letter-spacing: 0;
        font-family: "Poppins", sans-serif;
    }

    section, nav, footer, header, aside {
        display: block;
    }

    body {
        overflow-x: hidden;
         font-size: 16px;
    }

    input, select {
        font-size: 100%;
    }

    ul, ol {
       list-style-type: none;
    }

    a {
        text-decoration: none;
        display: inline-block;
    }

    input {
        appearance: none;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        appearance: none;
        margin: 0;
    }

    input[type=number] {
        appearance: textfield;
    }

    img {
        max-width: 100%;
        height: auto;
    }


    .fade-in-out {
        animation: fadeinout 4s linear forwards;
        opacity: 0;

        @keyframes fadeinout {
        10%, 90% {  opacity: 1; }
            100% {opacity: 0}
    }
}
`;

export default GlobalStyle;
