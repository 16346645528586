import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-simple-flex-grid';
import { Helmet } from 'react-helmet';

// components
import Footer from './Footer';
import Header from './Header';
import GlobalStyles from '../global/GlobalStyles';
import Container from '../global/Container';
import { SiteWrapper } from './styled';

// hooks
import useSiteMetadata from '../../hooks/useSiteMetadata';

const Layout = ({
    children,
    footerSections,
    hasContainer,
    header,
    lng,
    metatags,
    minimalFooter,
    minimalHeader,
    href,
    otherFeatures,
    path,
    showOurCustomers,
    showWantToKnowMore,
}) => {
    const { siteUrl } = useSiteMetadata();
    const envSiteUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : siteUrl;

    return (
        <>
            <Helmet>
                <html lang={lng} />
                <title>{metatags?.title || ''}</title>
                <meta name="description" content={metatags?.description || ''} />
                <link rel="canonical" href={`${envSiteUrl}/${lng}${href}`} />
                <link rel="alternate" hrefLang="de" href={`${envSiteUrl}/de${href}`} />
                <link rel="alternate" hrefLang="en" href={`${envSiteUrl}/en${href}`} />

                <link rel="apple-touch-icon" sizes="152x152" href="/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                <link rel="manifest" href="/site.webmanifest" />
                <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
                <meta name="msapplication-TileColor" content="#3c6a84" />
                <meta name="theme-color" content="#3c6a84" />

                <meta property="og:title" content={metatags?.title || ''} />
                <meta property="og:description" content={metatags?.description || ''} />

                <meta name="og:image" content="https://relevanceone.com/og-relevanceone.jpg" />
                <meta name="og:site_name" content="RelevanceOne" />
                <meta name="og:url" content={`${envSiteUrl}/${lng}${href}`} />
                <meta property="og:type" content="website" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="relevanceone.com" />
                <meta property="twitter:url" content={`${envSiteUrl}/${lng}${href}`} />
                <meta name="twitter:title" content={metatags?.title || ''} />
                <meta name="twitter:description" content={metatags?.description || ''} />
                <meta name="twitter:image" content="https://relevanceone.com/og-relevanceone.jpg" />
                {metatags?.description && (
                    <script type="application/ld+json">
                        {JSON.stringify({
                            '@context': 'https://schema.org',
                            '@type': 'Organization',
                            name: 'RelevanceOne',
                            logo:
                                'https://relevanceone.com/static/1e9e5a4482809a08975b6c46e235e920/b713f3a7bb45441ab68e8b266151af26.svg',
                            description: metatags.description,
                            url: `${envSiteUrl}/${lng}${href}`,
                            sameAs: [
                                'https://www.facebook.com/RelevanceOne',
                                'https://twitter.com/RelevanceOne',
                                'https://www.linkedin.com/company/relevance-one',
                            ],
                        })}
                    </script>
                )}
            </Helmet>
            <SiteWrapper>
                <Header data={header} path={path} lng={lng} minimal={minimalHeader} />
                {hasContainer ? (
                    <Container>
                        <GlobalStyles />
                        <main>
                            <Row justify="center">
                                <Col xs={12} md={10} lg={10}>
                                    {children}
                                </Col>
                            </Row>
                        </main>
                    </Container>
                ) : (
                    <>
                        <GlobalStyles />
                        <main>{children}</main>
                    </>
                )}
                <Footer
                    showWantToKnowMore={showWantToKnowMore}
                    sections={footerSections}
                    lng={lng}
                    path={path}
                    otherFeatures={otherFeatures}
                    minimal={minimalFooter}
                    showOurCustomers={showOurCustomers}
                />
            </SiteWrapper>
        </>
    );
};

Layout.defaultProps = {
    header: undefined,
    hasContainer: true,
    footerSections: [],
    otherFeatures: {},
    minimalHeader: false,
    minimalFooter: false,
    showOurCustomers: true,
    showWantToKnowMore: true,
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    header: PropTypes.shape({}),
    path: PropTypes.string.isRequired,
    lng: PropTypes.string.isRequired,
    hasContainer: PropTypes.bool,
    href: PropTypes.string.isRequired,
    footerSections: PropTypes.arrayOf(PropTypes.shape({})),
    metatags: PropTypes.shape({
        description: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
    }).isRequired,
    minimalHeader: PropTypes.bool,
    minimalFooter: PropTypes.bool,
    otherFeatures: PropTypes.shape({}),
    showOurCustomers: PropTypes.bool,
    showWantToKnowMore: PropTypes.bool,
};

export default Layout;
