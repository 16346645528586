import React, { useState, useEffect, useCallback, memo, useRef } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';

// components
import ListContent from './components/ListContent';
import { MenuWrapper, StyledButton, StyledIcon, Title } from './styled';

const Dropdown = ({
    buttonPaddding,
    children,
    disabled,
    downIcon,
    dropUp,
    fixed,
    hasDownIcon,
    height,
    id,
    items,
    onItemClick,
    onOpen,
    primary,
    position,
    selected,
    width,
}) => {
    const menuWrapperRef = useRef();

    const [show, setShow] = useState(false);

    const onClickEvent = useCallback(
        event => {
            if (show && !find(event.path, path => [id].includes(path.id))) {
                setShow(false);
                onOpen(false);
            }
        },
        [id, onOpen, show]
    );

    useEffect(() => {
        document.addEventListener('click', onClickEvent);

        return () => document.removeEventListener('click', onClickEvent);
    }, [onClickEvent]);

    const handleOnItemClick = ({ event, item, index }) => {
        onItemClick({ item, event, index });
        setShow(false);
        onOpen(false);
    };

    const handleSetShow = value => {
        setShow(value);
        onOpen(value);
    };

    return (
        <MenuWrapper ref={menuWrapperRef}>
            <StyledButton
                disabled={disabled}
                padding={buttonPaddding}
                onClick={() => handleSetShow(!show)}
                type="button"
                primary={primary}
            >
                <Title disabled={disabled}>{children}</Title>
                {hasDownIcon && (
                    <StyledIcon primary={primary} name={downIcon || 'keyboard_arrow_down'} />
                )}
            </StyledButton>
            {items ? (
                <ListContent
                    dropUp={dropUp}
                    fixed={fixed}
                    handleOnItemClick={handleOnItemClick}
                    height={height}
                    items={items}
                    position={position}
                    selected={selected}
                    show={show}
                    width={width}
                />
            ) : null}
        </MenuWrapper>
    );
};

Dropdown.defaultProps = {
    disabled: false,
    downIcon: undefined,
    dropUp: false,
    fixed: true,
    primary: false,
    buttonPaddding: '10px 0',
    hasDownIcon: true,
    height: 'auto',
    position: 'center',
    selected: null,
    width: 'auto',
    onItemClick: () => {},
    onOpen: () => {},
};

Dropdown.propTypes = {
    buttonPaddding: PropTypes.string,
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    downIcon: PropTypes.string,
    dropUp: PropTypes.bool,
    hasDownIcon: PropTypes.bool,
    height: PropTypes.string,
    id: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        })
    ).isRequired,
    onItemClick: PropTypes.func,
    onOpen: PropTypes.func,
    primary: PropTypes.bool,
    position: PropTypes.oneOf(['center', 'left', 'right']),
    selected: PropTypes.number,
    width: PropTypes.string,
    fixed: PropTypes.bool,
};

export default memo(Dropdown);
