import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import { colors } from '../../../theme';

export const Highlight = styled(Link)`
    text-align: center;
    padding: 24px;
    width: 100%;

    :hover {
        background: ${colors.lightBlue};
    }

    ${({ showonbig }) =>
        showonbig === 1 &&
        css`
            @media all and (max-width: 474px) {
                display: none;
            }
        `}
    ${({ showonsmall }) =>
        showonsmall === 1 &&
        css`
            @media all and (min-width: 475px) {
                display: none;
            }
        `}
`;

const Styled = css`
    background: ${colors.blue};
    padding: 8px 20px;
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 24px;
    text-decoration: none;
    word-break: keep-all;
    font-size: 0.875rem;
    border-radius: 3px;
    margin: 0 10px;

    @media all and (max-width: 991px) {
        font-size: 12px;
        padding: 4px 14px;
    }

    &:hover {
        background: #59adff;
    }

    &:focus {
        background: #1f7fdc;
    }

    ${({ transparent }) =>
        transparent === 1 &&
        css`
            background: #fff;
            border: 2px solid ${colors.blue};
            color: ${colors.blue};

            :hover {
                background: ${colors.blue};
                color: white;
            }

            :focus {
                background: rgba(35, 147, 255, 0.3);
                color: ${colors.blue};
            }
        `}

    ${props =>
        props.big === 1 &&
        css`
            font-size: 1.125rem;
            border-radius: 4px;
            box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.34);
            padding: 21px;
            margin: 0;

            @media all and (max-width: 768px) {
                padding: 12px 36px;
                font-size: 1rem;
            }
        `}

    ${props =>
        props.primary === 1 &&
        css`
            padding: 17px 22px;
            font-size: 1.125rem;
            border: 2px solid white;
            border-radius: 3px;
            background: transparent;
            margin: 0;
            font-weight: 600;

            :hover {
                background: white;
                color: ${colors.blue};
            }

            :focus {
                background: rgba(255, 255, 255, 0.3);
                color: white;
            }

            @media all and (max-width: 768px) {
                padding: 12px 36px;
                font-size: 1rem;
            }
        `}

${({ showonbig }) =>
        showonbig === 1 &&
        css`
            @media all and (max-width: 474px) {
                display: none;
            }
        `}

${({ showonsmall }) =>
        showonsmall === 1 &&
        css`
            @media all and (min-width: 475px) {
                display: none;
            }
        `}

    ${({ marginbottomonsmall }) =>
        marginbottomonsmall &&
        css`
            @media all and (max-width: 1024px) {
                margin-bottom: ${marginbottomonsmall};
            }

            @media all and (max-width: 768px) {
                margin-bottom: 150px;
            }
        `}
`;

export const StyledLink = styled(Link)`
    ${Styled}
`;

export const StyledSpan = styled.span`
    ${Styled}
`;
