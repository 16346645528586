// constants
import { LANGUAGES } from '../constants';

function notAnImageFiled(fieldKey) {
    return !/image/i.test(fieldKey);
}

function isObjectOrArray(field) {
    return typeof field === 'object';
}

function notNullOrUndefined(field) {
    return ![undefined, null].includes(field);
}

function shouldRecruise(field, fieldKey) {
    return isObjectOrArray(field) && notNullOrUndefined(field) && notAnImageFiled(fieldKey);
}

export function localiseData(obj, lng) {
    Object.keys(obj).forEach(key => {
        if (shouldRecruise(obj[key], key)) {
            localiseData(obj[key], lng);
        } else if (key.endsWith(`_${lng}`)) {
            obj[key.substring(0, key.length - (lng.length + 1))] = obj[key];
        }
    });

    return obj;
}

export function getLanguage(pageContext) {
    return LANGUAGES.includes(pageContext.langKey) ? pageContext.langKey : LANGUAGES[0];
}

export function preloadImages(sources) {
    const images = [];

    for (let i = 0; i < sources.length; i += 1) {
        images[i] = new Image();
        images[i].src = sources[i];
    }
}

export function sleep(ms) {
    return new Promise(res => {
        setTimeout(() => {
            res();
        }, ms);
    });
}
