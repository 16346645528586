import React from 'react';

const Logo = () => {
    return (
        <svg
            width="24px"
            height="31px"
            viewBox="0 0 24 31"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <title>E0B904B7-25D5-4789-B537-AE3496B397BC</title>
            <g id="Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                    id="M---Landing-page"
                    transform="translate(-24.000000, -5933.000000)"
                    fill="#FFFFFF"
                    fillRule="nonzero"
                >
                    <g id="Footer" transform="translate(0.000000, 5899.000000)">
                        <g id="Group-42" transform="translate(24.000000, 34.000000)">
                            <path
                                d="M2.07874688,21.4317989 C2.07874688,16.4854266 5.98393116,12.8182261 11.3095161,12.8182261 C16.6351011,12.8182261 20.5402853,16.4419421 20.5402853,21.4317989 C20.5402853,26.4216557 16.6094091,30.0489954 11.294835,30.0489954 C5.98026088,30.0489954 2.07874688,26.3817948 2.07874688,21.4317989 Z M16.5873873,21.4317989 C16.5873873,18.2682949 14.3228209,16.1049365 11.3095161,16.1049365 C8.29621133,16.1049365 6.03164488,18.279166 6.03164488,21.4317989 C6.03164488,24.5844317 8.29621133,26.762285 11.294835,26.762285 C14.2934586,26.762285 16.5873873,24.5989266 16.5873873,21.4317989 L16.5873873,21.4317989 Z"
                                id="Shape"
                            />
                            <path
                                d="M11.9292523,21.4335786 C11.9193191,21.0655817 12.0872374,20.7149772 12.3812477,20.4898361 C12.4803991,20.4263906 12.5402853,20.3174452 12.5402853,20.2005136 C12.5402853,20.0835821 12.4803991,19.9746366 12.3812477,19.9111911 C11.7194477,19.4614932 10.8413588,19.4820009 10.2016867,19.9620946 C9.56201458,20.4421883 9.3044334,21.2740362 9.56232788,22.0268777 C9.82022237,22.7797192 10.5356679,23.2844617 11.3381812,23.2797646 C11.711024,23.2794513 12.0749083,23.1665024 12.3812477,22.955966 C12.4803991,22.8925205 12.5402853,22.783575 12.5402853,22.6666435 C12.5402853,22.5497119 12.4803991,22.4407665 12.3812477,22.377321 C12.0872374,22.1521799 11.9193191,21.8015754 11.9292523,21.4335786 Z"
                                id="Path"
                            />
                            <rect
                                id="Rectangle"
                                x="9.46336227"
                                y="0.510533817"
                                width="3.07692308"
                                height="6.15384615"
                                rx="1.53846154"
                            />
                            <path
                                d="M2.66005571,4.69656341 C3.48434803,4.69916307 4.15192608,5.36673343 4.15453524,6.19102572 L4.15679168,6.90388268 C4.15937963,7.7214737 3.49868909,8.38636012 2.68109807,8.38894807 C2.67797984,8.38895794 2.67486158,8.38895796 2.67174334,8.38894813 C1.84745102,8.38634847 1.17987297,7.71877811 1.17726381,6.89448582 L1.17500738,6.18162886 C1.17241943,5.36403784 1.83310996,4.69915142 2.65070098,4.69656347 C2.65381922,4.6965536 2.65693747,4.69655358 2.66005571,4.69656341 Z"
                                id="Rectangle"
                                transform="translate(2.665900, 6.542756) rotate(-30.000000) translate(-2.665900, -6.542756) "
                            />
                            <path
                                d="M20.3441202,0.539187523 C21.1617112,0.536608998 21.82659,1.19730719 21.8291686,2.01489824 C21.8291784,2.01801648 21.8291784,2.02113474 21.8291685,2.02425297 L21.8128869,7.16797159 C21.8102778,7.99226388 21.1426997,8.65983424 20.3184074,8.6624339 C19.5008163,8.66501242 18.8359375,8.00431423 18.833359,7.18672317 C18.8333492,7.18360494 18.8333492,7.18048668 18.833359,7.17736844 L18.8496406,2.03364983 C18.8522498,1.20935754 19.5198278,0.541787182 20.3441202,0.539187523 Z"
                                id="Rectangle"
                                transform="translate(20.331264, 4.600811) rotate(30.000000) translate(-20.331264, -4.600811) "
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default Logo;
