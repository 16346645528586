/* eslint-disable react/no-danger */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import marked from 'marked';
import { useStaticQuery, graphql } from 'gatsby';
import { Col, Row } from 'react-simple-flex-grid';

import isEmpty from 'lodash/isEmpty';
import { localiseData } from '../../../utils/helpers';

import {
    Background,
    CenteredDiv,
    // ColInnerContainer,
    CopyrightContainer,
    FooterTitleContainer,
    Image,
    LinksContainer,
    LinksHeading6,
    // LinksParagraph,
    LowerFooterLinksContainer,
    // NewsletterContainer,
    PrivacyContainer,
    PrivacyBox,
    RichText,
    Section,
    // ShowOnBig,
    SocialNetworkIconsContainer,
    LogoWrapper,
    LogosContainer,
} from './styled';
import { Paragraph, Heading2, Heading4 } from '../../global/Typography';
import Container from '../../global/Container';
import Button from '../../global/ButtonLink';
// import Input from '../../global/Input';
import LowerLink from './components/LowerLink';
import OtherFeatures from './components/OtherFeatures';
import Logo from './components/Logo';

// import { validateNewsletter } from '../../../utils/validators';
// import { postSlackMessage } from '../../../utils/slack';

const Footer = ({
    sections,
    lng,
    path,
    otherFeatures,
    minimal,
    showOurCustomers,
    showWantToKnowMore,
}) => {
    const pageQuery = useStaticQuery(graphql`
        query FooterQuery {
            strapiFooter {
                background {
                    publicURL
                }
                button {
                    href
                    label_en
                    label_de
                }
                copyright_en
                copyright_de
                footerLinks {
                    links_en
                    links_de
                    title_en
                    title_de
                    id
                }
                footerLowerLinks {
                    footerLowerLink {
                        href
                        id
                        icon {
                            publicURL
                        }
                        options {
                            label_en
                            label_de
                            value
                        }
                        title_en
                        title_de
                    }
                }
                footerSection {
                    description_en
                    description_de
                    logos {
                        customerlogo {
                            publicURL
                            name
                        }
                    }
                    title_en
                    title_de
                }
                image {
                    publicURL
                }
                title_en
                title_de
            }
        }
    `);

    const { strapiFooter: data } = useMemo(() => localiseData(pageQuery, lng), [lng, pageQuery]);

    // const [errors, setErrors] = useState({});
    // const [email, setEmail] = useState('');
    // const [isLoading, setIsLoading] = useState(false);

    const copyrightContent = (
        <CopyrightContainer>
            <Container>
                <Paragraph small>{data.copyright}</Paragraph>
            </Container>
        </CopyrightContainer>
    );

    if (minimal) {
        return copyrightContent;
    }

    const splittedTitle = data.title.split('? ');

    // async function handleOnNewslettterSubmit(event) {
    //     event.preventDefault();
    //     const { errors: foundErrors, isValid } = validateNewsletter(email);

    //     setErrors(foundErrors);

    //     if (isValid) {
    //         setIsLoading(true);

    //         const { error } = await postSlackMessage(`New newsletter subscription: *${email}*`);

    //         if (!error) {
    //             setIsLoading(false);
    //             setEmail('');
    //         } else {
    //             setIsLoading(false);
    //         }
    //     }
    // }

    return (
        <>
            {showOurCustomers ? (
                <Container marginBottom="100px">
                    <CenteredDiv>
                        <Heading2 marginBottom="24px">{data.footerSection.title}</Heading2>
                        <Paragraph>{data.footerSection.description}</Paragraph>
                    </CenteredDiv>
                    <LogosContainer>
                        <Row gutter="24" align="stretch">
                            {data?.footerSection?.logos?.map(({ customerlogo }) => (
                                <Col xs={6} sm={3} md={2} key={customerlogo.name}>
                                    <LogoWrapper>
                                        <img src={customerlogo.publicURL} alt="customer logo" />
                                    </LogoWrapper>
                                </Col>
                            ))}
                        </Row>
                    </LogosContainer>
                </Container>
            ) : null}
            <Container marginBottom="190px">
                {sections.length !== 0
                    ? sections.map(section => (
                          <Section key={section.id}>
                              <Row gutter={24} justify="stretch">
                                  <Col xs={12} sm={6}>
                                      <Background>
                                          <div>
                                              <Heading4 marginBottom="24px">
                                                  {section.title}
                                              </Heading4>
                                              <Paragraph marginbottomonsmall="24px">
                                                  {section.description}
                                              </Paragraph>
                                          </div>
                                      </Background>
                                  </Col>
                                  <Col xs={12} sm={6}>
                                      <Background src={section.background.publicURL}>
                                          <Image
                                              src={section.image.publicURL}
                                              alt={section.title}
                                          />
                                      </Background>
                                  </Col>
                              </Row>
                          </Section>
                      ))
                    : null}
            </Container>

            {!isEmpty(otherFeatures) && (
                <Container narrow>
                    <OtherFeatures otherFeatures={otherFeatures} lng={lng} />
                </Container>
            )}
            {showWantToKnowMore && (
                <FooterTitleContainer src={data.background.publicURL}>
                    <Container>
                        <Image src={data.image.publicURL} alt="footer photo" showOnBigScreen />
                        {splittedTitle.map((title, index) => (
                            <Heading2
                                key={index}
                                big={index === splittedTitle.length - 1}
                                marginRight="20%"
                                bold
                                marginBottom={index === splittedTitle.length - 1 ? '36px' : '16px'}
                            >
                                {index === 0 ? `${title}?` : title}
                            </Heading2>
                        ))}
                        <Button
                            label={data.button.label}
                            href={data.button.href}
                            lng={lng}
                            isBig
                            marginbottomonsmall="300px"
                        />
                    </Container>
                </FooterTitleContainer>
            )}

            <LinksContainer>
                <Container padding="80px 24px">
                    {/* <ShowOnSmall>
                        <Image src={data.newsletter.image.publicURL} />
                    </ShowOnSmall> */}
                    <Logo />
                    <Row gutter={48} justify="stretch">
                        {/* <Col
                            xs={{ span: 12, order: 1 }}
                            sm={{ span: 6, order: 0 }}
                            md={{ span: 3, order: 0 }}
                        >
                            <NewsletterContainer>
                                <ShowOnBig>
                                    <Image src={data.newsletter.image.publicURL} />
                                </ShowOnBig>
                                <div>
                                    <LinksHeading6 marginBottom="5px">
                                        {data.newsletter.title}
                                    </LinksHeading6>
                                    <LinksParagraph marginBottom="10px">
                                        {data.newsletter.description}
                                    </LinksParagraph>
                                    <form onSubmit={handleOnNewslettterSubmit}>
                                        <Input
                                            id="email"
                                            label={data.newsletter.emailLabel}
                                            name="email"
                                            error={errors.email}
                                            onChange={setEmail}
                                            value={email}
                                            isLoading={isLoading}
                                        />
                                    </form>
                                </div>
                            </NewsletterContainer>
                        </Col> */}
                        {data.footerLinks.map(footerLink => (
                            <Col
                                xs={12}
                                sm={4}
                                md={3}
                                // md={{
                                //     span: index === 0 ? 3 : 2,
                                //     offset: index === 0 ? 2 : 0,
                                //     order: 1,
                                // }}
                                key={footerLink.id}
                            >
                                <LinksHeading6 uppercase>{footerLink.title}</LinksHeading6>
                                {footerLink.links && (
                                    <RichText
                                        footerLinks
                                        dangerouslySetInnerHTML={{
                                            __html: marked(footerLink.links),
                                        }}
                                    />
                                )}
                            </Col>
                        ))}
                    </Row>
                </Container>
            </LinksContainer>
            <PrivacyContainer>
                <Container>
                    <LowerFooterLinksContainer>
                        <Row gutter={24}>
                            <Col xs={12} sm={9} md={6}>
                                <PrivacyBox>
                                    <LowerLink
                                        link={data.footerLowerLinks.footerLowerLink[0]}
                                        lng={lng}
                                        path={path}
                                    />
                                    <LowerLink
                                        paddingRight="5px"
                                        link={data.footerLowerLinks.footerLowerLink[1]}
                                        lng={lng}
                                        path={path}
                                    />
                                </PrivacyBox>
                            </Col>
                            <Col xs={12} sm={3} md={{ span: 3, offset: 3 }}>
                                <SocialNetworkIconsContainer>
                                    <LowerLink
                                        link={data.footerLowerLinks.footerLowerLink[2]}
                                        lng={lng}
                                        path={path}
                                    />
                                    <LowerLink
                                        link={data.footerLowerLinks.footerLowerLink[3]}
                                        lng={lng}
                                        path={path}
                                    />
                                    <LowerLink
                                        link={data.footerLowerLinks.footerLowerLink[4]}
                                        lng={lng}
                                        path={path}
                                    />
                                </SocialNetworkIconsContainer>
                            </Col>
                        </Row>
                    </LowerFooterLinksContainer>
                </Container>
            </PrivacyContainer>
            {copyrightContent}
        </>
    );
};

Footer.propTypes = {
    lng: PropTypes.string.isRequired,
    minimal: PropTypes.bool.isRequired,
    otherFeatures: PropTypes.shape({}).isRequired,
    path: PropTypes.string.isRequired,
    sections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    showOurCustomers: PropTypes.bool.isRequired,
    showWantToKnowMore: PropTypes.bool.isRequired,
};

export default Footer;
