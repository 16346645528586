import React from 'react';
import PropTypes from 'prop-types';

import { StyledLink, Highlight, StyledSpan } from './styled';

const Button = ({
    highlighted,
    href,
    isBig,
    label,
    lng,
    primary,
    showonbig,
    showonsmall,
    transparent,
    marginbottomonsmall,
}) => {
    return highlighted ? (
        <Highlight to={`/${lng}${href}`} showonbig={showonbig} showonsmall={showonsmall}>
            <StyledSpan
                to={`/${lng}${href}`}
                big={isBig ? 1 : 0}
                transparent={transparent ? 1 : 0}
                primary={primary}
                showonbig={showonbig}
                showonsmall={showonsmall}
                marginbottomonsmall={marginbottomonsmall}
            >
                {label}
            </StyledSpan>
        </Highlight>
    ) : (
        <StyledLink
            to={`/${lng}${href}`}
            big={isBig ? 1 : 0}
            transparent={transparent ? 1 : 0}
            primary={primary}
            showonbig={showonbig}
            showonsmall={showonsmall}
            marginbottomonsmall={marginbottomonsmall}
        >
            {label}
        </StyledLink>
    );
};

Button.defaultProps = {
    highlighted: false,
    isBig: false,
    primary: 0,
    showonbig: 0,
    showonsmall: 0,
    transparent: false,
    marginbottomonsmall: '',
};

Button.propTypes = {
    highlighted: PropTypes.bool,
    href: PropTypes.string.isRequired,
    isBig: PropTypes.bool,
    label: PropTypes.string.isRequired,
    lng: PropTypes.string.isRequired,
    primary: PropTypes.number,
    showonbig: PropTypes.number,
    showonsmall: PropTypes.number,
    transparent: PropTypes.bool,
    marginbottomonsmall: PropTypes.string,
};

export default Button;
