import styled, { css } from 'styled-components';

const Container = styled.div`
    max-width: ${({ narrow }) => (narrow ? '970px' : '1200px')};
    padding: ${({ padding }) => padding || '0 20px'};
    margin: 0 auto;
    position: relative;
    ${({ marginBottom }) =>
        marginBottom &&
        css`
            margin-bottom: ${marginBottom};

            @media all and (max-width: 991px) {
                margin-bottom: ${marginBottom ? '56px' : '0'};
            }
        `};
    ${({ marginTop }) =>
        marginTop &&
        css`
            margin-top: ${marginTop};

            @media all and (max-width: 991px) {
                margin-top: 0;
            }
        `};
`;

export default Container;
