import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { LowerFooterLink, Image, LinksHeading6 } from '../styled';
import Dropdown from '../../../global/Dropdown';

import switchLanguage from '../../../../utils/switchLanguage';

const LowerLink = ({ link, paddingRight, path, lng }) => {
    function handleOnItemClick(item) {
        switchLanguage(item.value, path, lng);
    }

    if (link.options) {
        return (
            <LowerFooterLink>
                <Dropdown
                    buttonPaddding="0"
                    items={link.options}
                    onItemClick={({ item }) => handleOnItemClick(item)}
                    id={link.id}
                    downIcon="arrow_drop_down"
                    primary
                    dropUp
                    position="left"
                >
                    {link.icon ? (
                        <Image
                            icon
                            alt="icon illustration"
                            src={link.icon.publicURL}
                            paddingRight={paddingRight}
                        />
                    ) : null}
                    <LinksHeading6>{link.title}</LinksHeading6>
                </Dropdown>
            </LowerFooterLink>
        );
    }

    return (
        <LowerFooterLink>
            {/facebook|twitter|linkedin/gi.test(link.href) ? (
                <a href={link.href} target="_blank" rel="noreferrer nofollow">
                    {link.icon ? (
                        <Image
                            icon
                            alt="social network logo"
                            src={link.icon.publicURL}
                            paddingRight={paddingRight}
                        />
                    ) : null}
                    <LinksHeading6>{link.title}</LinksHeading6>
                </a>
            ) : (
                <Link to={`/${lng}${link.href}`}>
                    {link.icon ? (
                        <Image
                            icon
                            alt="icon illustration"
                            src={link.icon.publicURL}
                            paddingRight={paddingRight}
                        />
                    ) : null}
                    <LinksHeading6>{link.title}</LinksHeading6>
                </Link>
            )}
        </LowerFooterLink>
    );
};

LowerLink.defaultProps = {
    paddingRight: undefined,
};

LowerLink.propTypes = {
    link: PropTypes.shape({
        id: PropTypes.string.isRequired,
        icon: PropTypes.shape({
            publicURL: PropTypes.string,
        }),
        href: PropTypes.string,
        title: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    paddingRight: PropTypes.string,
    path: PropTypes.string.isRequired,
    lng: PropTypes.string.isRequired,
};

export default LowerLink;
