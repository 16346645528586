import styled, { css } from 'styled-components';

import { Heading5, Paragraph } from '../../global/Typography';
import Icon from '../../global/Icon';

import { colors } from '../../../theme';

export const Background = styled.div`
    ${props =>
        props.src
            ? `
                background: url(${props.src});
                background-size: contain;
                background-position: right;
                background-repeat: no-repeat;

                @media only screen and (max-width: 510px) {
                    background: transparent;
                }
                `
            : ''}
    padding: 64px 0;
    height: 100%;
    display: flex;
    align-items: center;

    @media all and (max-width: 991px) {
        padding: 0;
    }
`;

export const CenteredDiv = styled.div`
    text-align: center;
    margin-bottom: 24px;

    @media all and (max-width: 768px) {
        text-align: left;
    }
`;

export const ColInnerContainer = styled.div`
    @media all and (max-width: 768px) {
        margin-bottom: 28px;
    }
`;

export const CopyrightContainer = styled.div`
    background: #165da3;
    padding: 13px 0;
`;

export const FooterTitleContainer = styled.div`
    background: url(${props => props.src});
    width: 100%;
    min-height: 36vw;
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;

    @media all and (max-width: 991px) {
        background-size: contain;
    }

    @media all and (max-width: 768px) {
        max-height: 72vw;
    }

    @media all and (max-width: 500px) {
        max-height: 90vw;
    }
`;

export const Image = styled.img`
    ${props => (props.background ? 'background: #f8f8f8;' : '')}
    border-radius: 16px;
    margin-bottom: 31px;

    @media all and (max-width: 991px) {
        margin-bottom: ${props => (props.icon ? '0' : ' 24px')};
    }

    ${({ maxHeight }) =>
        maxHeight &&
        css`
            max-height: ${maxHeight};
        `}
    ${({ height }) =>
        height &&
        css`
            height: ${height};
        `}
        ${({ width }) =>
        width &&
        css`
            width: ${width};
        `}

    ${props =>
        props.icon &&
        css`
            border-radius: 0;
            margin-bottom: 0;
            ${props.paddingRight ? `padding-right: ${props.paddingRight}` : '0'};
        `}
    ${props =>
        props.showOnBigScreen &&
        css`
            position: absolute;
            left: -75px;
            top: -75px;
            @media only screen and (max-width: 1300px) {
                display: none;
            }
        `}
`;

export const LinksContainer = styled.div`
    background: #1d7bd6;

    > div {
        @media all and (max-width: 768px) {
            padding: 34px 20px;
        }
    }

    svg {
        margin-bottom: 20px;
        @media all and (min-width: 601px) {
            display: none;
        }
    }
`;

export const LinksHeading6 = styled(Heading5)`
    color: white;

    ${({ uppercase }) =>
        uppercase
            ? css`
                  text-transform: uppercase;
                  color: rgba(255, 255, 255, 0.72);
                  padding: 16px 0;
              `
            : ''}
`;

export const LinksParagraph = styled(Paragraph)`
    color: rgba(255, 255, 255, 0.72);
    font-size: 0.875rem;
    line-height: 24px;
`;

export const LowerFooterLink = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    /* @media all and (max-width: 601px) {
        padding: 0;

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    } */
`;

export const LowerFooterLinksContainer = styled.div`
    padding: 30px 0;

    @media all and (max-width: 601px) {
        padding: 20px 0;
    }
`;

export const NewsletterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    justify-content: space-between;

    @media all and (max-width: 991px) {
        h5,
        p {
            margin-bottom: 5px;
        }
        input {
            margin-bottom: 24px;
        }
    }
    @media all and (max-width: 768px) {
        input {
            margin-bottom: 0;
        }
    }
`;

export const PrivacyBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    @media all and (max-width: 767px) {
        margin-bottom: 30px;
    }

    > div {
        &:first-child {
            margin-right: 20px;
        }

        @media all and (max-width: 768px) {
            margin-bottom: 12px;
        }
    }

    button {
        :first-of-type {
            margin-right: 48px;
        }
    }
`;

export const PrivacyContainer = styled.div`
    background: #1a6ebf;
`;

export const RichText = styled.div`
    a {
        color: ${colors.blue};
        font-weight: 600;

        :hover {
            color: #59adff;
        }

        :focus {
            color: rgba(0, 0, 0, 0.54);
        }
    }

    p {
        margin-top: 16px;
        color: rgba(0, 0, 0, 0.64);
        font-size: 0.9375rem;

        @media all and (max-width: 768px) {
            font-size: 0.875rem;
            line-height: 24px;
        }
    }

    h4 {
        font-size: 1.25rem;
        color: black;

        @media all and (max-width: 768px) {
            font-size: 1.125rem;
        }
    }

    h6 {
        color: rgba(0, 0, 0, 0.64);
        font-size: 0.9375rem;
        font-weight: 500;

        a {
            color: ${colors.blue};
            font-weight: 600;

            :hover {
                color: #59adff;
            }

            :focus {
                color: rgba(0, 0, 0, 0.54);
            }
        }

        @media all and (max-width: 768px) {
            font-size: 0.875rem;
        }
    }

    ul {
        @media all and (max-width: 991px) {
            margin-bottom: 24px;
        }
    }

    ${({ footerLinks }) =>
        footerLinks &&
        css`
            a,
            p {
                color: white;
                font-size: 1rem;
            }

            a {
                padding: 9px 0;

                @media all and (max-width: 601px) {
                    font-size: 14px;
                }
            }
        `}
`;

export const Section = styled.div``;

export const SocialNetworkIconsContainer = styled.div`
    display: flex;
    align-items: center;

    > div {
        margin: 0 20px;

        @media all and (max-width: 768px) {
            margin-left: 0;
            margin-right: 40px;
        }
    }
`;

export const StyledButton = styled.button`
    border: 0;
    background: transparent;
    outline: none;
    cursor: pointer;

    :hover {
        h5 {
            color: ${colors.blue};
        }
    }
`;

export const ArrowRight = styled(Icon)`
    color: ${colors.blue};
    font-size: 3.5rem;
    margin-left: auto;
    margin-right: 10px;
    display: none;
`;

export const ButtonDescription = styled.div`
    display: block;
    font-size: 1rem;
    color: rgba(5, 6, 14, 0.54);
    font-weight: 600;
    text-align: left;
`;

export const ImageDiv = styled.div`
    min-height: 64px;
    min-width: 64px;
    margin-right: 36px;

    @media all and (max-width: 601px) {
        margin-right: 24px;
    }
`;

export const ButtonText = styled.div`
    width: 100%;
    color: #000000;
    font-size: 1.25rem;
    display: block;
    font-weight: 600;
    text-align: left;

    @media all and (max-width: 601px) {
        font-size: 16px;
    }
`;

export const StyledButtonWithPadding = styled(StyledButton)`
    width: 100%;
    padding: 15px;
    display: flex;
    align-items: center;

    @media all and (max-width: 601px) {
        padding-left: 0;
        padding-right: 0;
    }

    span {
        ${props =>
            props.src
                ? css`
                      background: url(${props.src});
                      background-position: center;
                      background-repeat: no-repeat;
                      background-size: cover;
                      height: 64px;
                      width: 64px;
                      display: block;
                  `
                : ''}
    }

    i {
        display: none;
    }

    @media all and (min-width: 768px) {
        &:hover {
            box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.16);
            border-radius: 12px;

            span {
                ${props =>
                    props.srcHover
                        ? css`
                              background: url(${props.srcHover});
                              background-position: center;
                              background-repeat: no-repeat;
                              background-size: cover;
                              height: 64px;
                              width: 64px;
                              display: block;
                          `
                        : ''}
            }

            i {
                display: inherit;
                font-size: 2.5rem;
            }
        }
    }
`;

export const OtherFeaturesContainer = styled.div`
    margin-bottom: 130px;

    @media all and (max-width: 991px) {
        margin-bottom: 56px;

        h2 {
            margin-bottom: 20px;
        }
    }

    @media all and (max-width: 767px) {
        margin-bottom: 28px;
    }
`;

export const ShowOnBig = styled.div`
    @media all and (max-width: 767px) {
        display: none;
    }
`;
export const ShowOnSmall = styled.div`
    @media all and (min-width: 768px) {
        display: none;
    }
`;

export const LogosContainer = styled.div`
    div[class^='rct-grid-col'] {
        margin-bottom: 40px;

        @media all and (max-width: 1024px) {
            margin-bottom: 20px;
        }
    }
`;

export const LogoWrapper = styled.div`
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
`;
