import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
    Button,
    ButtonDescription,
    ButtonInnerWrapper,
    ButtonText,
    ImageDiv,
    StyledButtonWithPadding,
    StyledIcon,
    UnorderedList,
} from './styled';

const Collapse = ({ items, onItemClick, label }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    return (
        <>
            <Button type="button" onClick={() => setIsCollapsed(!isCollapsed)}>
                <ButtonInnerWrapper>
                    <div>
                        <span>{label}</span>
                    </div>
                    <div>
                        <StyledIcon name="keyboard_arrow_down" />
                    </div>
                </ButtonInnerWrapper>
            </Button>
            {isCollapsed && (
                <UnorderedList>
                    {items.map(item => (
                        <li key={item.value}>
                            <StyledButtonWithPadding
                                onClick={() => onItemClick({ item })}
                                type="button"
                                src={item.iconDefault}
                                srcHover={item.iconHover}
                            >
                                {item.iconDefault && item.iconHover ? (
                                    <ImageDiv>
                                        <span />
                                    </ImageDiv>
                                ) : null}
                                <div>
                                    <ButtonText leftPadding={!item.iconDefault || !item.iconHover}>
                                        {item.label}
                                    </ButtonText>
                                    {item.description ? (
                                        <ButtonDescription>{item.description}</ButtonDescription>
                                    ) : null}
                                </div>
                            </StyledButtonWithPadding>
                        </li>
                    ))}
                </UnorderedList>
            )}
        </>
    );
};

Collapse.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            iconDefault: PropTypes.string,
            iconHover: PropTypes.string,
            label: PropTypes.string.isRequired,
            description: PropTypes.string,
        })
    ).isRequired,
    onItemClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
};

export default Collapse;
