import styled, { css } from 'styled-components';
import { colors } from '../../../theme';

// components
import Icon from '../Icon';

const POSITIONS = {
    center: css`
        left: 50%;
        transform: translateX(-50%);
    `,
    left: css`
        right: 0;
    `,
    right: css`
        left: 0;
    `,
};

export const ArrowRight = styled(Icon)`
    color: ${colors.blue};
    font-size: 2.5rem;
    margin-left: auto;
    margin-right: 10px;
    display: none;
`;

export const ButtonDescription = styled.div`
    display: block;
    font-size: 0.875rem;
    color: ${colors.captionGrey};
    font-weight: 400;
`;

export const DropUpList = css`
    top: inherit;
    bottom: 40px;
`;

export const ImageDiv = styled.div`
    min-height: 50px;
    min-width: 50px;
    margin-right: 20px;
`;

export const List = styled.ul`
    border-radius: 3px;
    display: ${props => (props.show ? 'block' : 'none')};
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
    position: absolute;
    width: ${({ width }) => width};
    background: white;
    text-align: left;
    top: 40px;
    z-index: 1000;
    overflow-y: auto;
    padding: 0;
    ${props => POSITIONS[props.position]}
    ${props => (props.dropUp ? DropUpList : null)}
`;

export const StyledButton = styled.button`
    cursor: pointer;
    display: flex;
    ${props => (props.disabled ? 'opacity: 0.4;' : '')}
    align-items: center;
    padding: ${props => props.padding};
    height: 100%;
    user-select: none;
    background: transparent;
    border: 0;
    font-size: 1rem;
    font-weight: 600;
    width: 100%;
    text-align: left;
    outline: 0;

    :hover {
        div,
        i {
            color: ${colors.blue};
        }
    }

    :focus {
        border: 2px solid ${colors.blue};
        border-radius: 3px;
        padding: 6px 8px 6px 8px;
        margin: -6px -10px;
        width: fit-content;
    }

    ${({ primary }) =>
        primary &&
        css`
            color: white;

            :hover {
                div,
                div h5,
                i {
                    color: ${colors.blue};
                }
            }

            :focus {
                border: 0;
                padding: 0;
                margin: 0;
            }
        `}
`;

export const StyledIcon = styled(Icon)`
    color: ${colors.black};
    ${({ primary }) => primary && 'color: white'};
`;

const SecondaryButtonText = css`
    padding-left: 50px;
    color: red;
    width: fit-content;
`;

const PrimaryButtonText = css`
    width: 100%;
    white-space: nowrap;
    color: ${colors.black};
    font-size: 0.9375rem;
`;

export const ButtonText = styled.div`
    display: block;
    ${props => (props.secondary ? SecondaryButtonText : PrimaryButtonText)}
`;

export const StyledButtonWithPadding = styled(StyledButton)`
    &:hover {
        background-color: ${colors.lightBlue};
        transition: all 0.2s;
    }

    :focus {
        border: 0;
        padding: 15px;
        margin: 0;
        width: 100%;
    }

    padding: 15px;

    display: flex;
    align-items: center;

    span {
        ${props =>
            props.src
                ? css`
                      background: url(${props.src});
                      background-position: center;
                      background-repeat: no-repeat;
                      background-size: cover;
                      height: 50px;
                      width: 50px;
                      display: block;
                  `
                : ''}
    }

    i {
        display: none;
    }

    &:hover {
        span {
            ${props =>
                props.srcHover
                    ? css`
                          background: url(${props.srcHover});
                          background-position: center;
                          background-repeat: no-repeat;
                          background-size: cover;
                          height: 50px;
                          width: 50px;
                          display: block;
                      `
                    : ''}
        }

        i {
            display: inherit;
            font-size: 2.5rem;
        }
    }
`;

export const MenuWrapper = styled.div`
    position: relative;
    margin-left: 0px;
`;

export const Title = styled.div`
    color: ${colors.black};
    font-size: 1rem;
    display: flex;
    align-items: center;
    font-weight: 600;
`;
