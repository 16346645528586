import styled, { css } from 'styled-components';
import { colors } from '../../../theme';

export const BigMenu = styled.div`
    @media all and (max-width: 999px) {
        display: none;
    }
`;

export const ButtonLinkContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const ButtonRight = styled.div`
    flex-grow: 1;
    text-align: right;
    padding-right: 40px;
`;

export const FixedDiv = styled.div`
    position: sticky;
    top: 0;
    left: 0;
    z-index: 105;
    background: white;
    width: 100%;
`;

export const HamburgerMenuContainer = styled.ul`
    background: white;
    position: absolute;
    height: calc(100vh - 59px);
    top: 59px;
    left: 0;
    right: 0;
    z-index: 105;
    text-align: left;
    overflow-y: auto;

    @media all and (max-width: 991px) {
        height: calc(100vh - 52px);
        top: 52px;
    }
`;

export const HeaderWrapper = styled.div`
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.08);
    padding: 10px 0;
`;

export const HeaderContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 106;
    top: 0;
    position: relative;
    background: white;

    ${({ hasPadding }) =>
        hasPadding &&
        css`
            padding: 10px 24px;
            @media all and (max-width: 474px) {
                padding: 14px 24px;
            }
        `}

    ${({ hasBottomBorder }) =>
        hasBottomBorder &&
        css`
            box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.08);
        `}

        ${({ minimal }) =>
        minimal &&
        css`
            padding: 1px 0;

            @media all and (max-width: 991px) {
                padding: 4px 4px;
            }
        `}
`;

export const HeaderLink = styled.div`
    font-weight: 600;
    font-size: 1rem;
    padding: 0 16px;
`;

export const Highlight = styled.div`
    width: 100%;
    font-weight: 600;

    a {
        color: rgba(0, 0, 0, 0.78);
        width: 100%;
        padding: 20px 24px;
    }

    :hover {
        background-color: ${colors.lightBlue};
    }
`;

export const Links = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
`;

export const Logo = styled.img`
    width: 193px;
    height: 31px;

    ${({ small }) =>
        small &&
        css`
            width: 150px;
            height: 24px;
        `}

    @media all and (max-width: 991px) {
        width: 150px;
        height: 24px;
    }
`;

export const SmallMenu = styled.div`
    position: sticky;
    width: 100%;
    background: white;
    @media all and (min-width: 1000px) {
        display: none;
    }
    top: 0;
    left: 0;
    z-index: 105;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.08);
    text-align: left;
`;

export const StyledButton = styled.button`
    cursor: pointer;
    background: transparent;
    border: 0;
    outline: 0;
    height: 24px;

    i {
        color: rgba(0, 0, 0, 0.64);
    }
`;

export const StyledLink = styled.div`
    a {
        color: ${colors.black};
        text-decoration: none;

        :hover {
            color: ${colors.blue};
        }

        :focus {
            border: 2px solid ${colors.blue};
            border-radius: 3px;
            padding: 6px 8px 6px 8px;
            margin: -6px -10px;
            width: fit-content;
        }
    }
`;
