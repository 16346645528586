import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-simple-flex-grid';
import { navigate } from 'gatsby';

import {
    ButtonText,
    ButtonDescription,
    OtherFeaturesContainer,
    ArrowRight,
    StyledButtonWithPadding,
    ImageDiv,
} from '../styled';
import { Heading2 } from '../../../global/Typography';

import { getIconSources } from './helpers';
import { preloadImages } from '../../../../utils/helpers';

const OtherFeatures = ({ otherFeatures, lng }) => {
    function handleOnLinkClick(href) {
        navigate(`/${lng}${href}`);
    }

    useEffect(() => {
        preloadImages(getIconSources(otherFeatures));
    }, [otherFeatures]);

    return (
        <OtherFeaturesContainer>
            <Heading2 center marginBottom="40px">
                {otherFeatures.title}
            </Heading2>
            <Row gutter={24}>
                {otherFeatures.site_links.map(link => (
                    <Col xs={12} sm={6} key={link.id}>
                        <StyledButtonWithPadding
                            key={link.id}
                            onClick={() => handleOnLinkClick(link.href)}
                            type="button"
                            src={link.iconDefault.publicURL}
                            srcHover={link.iconHover.publicURL}
                        >
                            <ImageDiv>
                                <span />
                            </ImageDiv>
                            <div>
                                <ButtonText>{link.label}</ButtonText>
                                <ButtonDescription>{link.description}</ButtonDescription>
                            </div>
                            <ArrowRight name="arrow_right_alt" />
                        </StyledButtonWithPadding>
                    </Col>
                ))}
            </Row>
        </OtherFeaturesContainer>
    );
};

OtherFeatures.propTypes = {
    lng: PropTypes.string.isRequired,
    otherFeatures: PropTypes.shape({
        title: PropTypes.string.isRequired,
        site_links: PropTypes.arrayOf(
            PropTypes.shape({
                href: PropTypes.string.isRequired,
                iconDefault: PropTypes.shape({
                    publicURL: PropTypes.string.isRequired,
                }),
                iconHover: PropTypes.shape({
                    publicURL: PropTypes.string.isRequired,
                }),
                label: PropTypes.string.isRequired,
                description: PropTypes.string.isRequired,
            })
        ).isRequired,
    }).isRequired,
};

export default OtherFeatures;
