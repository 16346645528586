import styled, { css } from 'styled-components';

import Icon from '../Icon';

import { colors } from '../../../theme';

export const Button = styled.button`
    background: transparent;
    border: 0;
    outline: none;
    font-size: 1rem;
    cursor: pointer;
    font-weight: 600;
    padding: 20px 24px;
    width: 100%;

    :hover {
        background: ${colors.lightBlue};
    }
`;

export const ButtonInnerWrapper = styled.div`
    display: flex;
    align-itmes: center;
    justify-content: flex-start;

    span {
        color: rgba(0, 0, 0, 0.72);
        padding-right: 16px;
    }
`;

export const StyledIcon = styled(Icon)`
    color: rgba(0, 0, 0, 0.72);
`;

export const StyledButton = styled.button`
    cursor: pointer;
    display: flex;
    ${props => (props.disabled ? 'opacity: 0.4;' : '')}
    align-items: center;
    padding: ${props => props.padding};
    height: 100%;
    user-select: none;
    background: transparent;
    border: 0;
    font-size: 1rem;
    font-weight: 600;
    width: 100%;
    text-align: left;
    outline: 0;
`;

export const StyledButtonWithPadding = styled(StyledButton)`
    &:hover {
        background-color: ${colors.lightBlue};
        transition: all 0.2s;
    }

    padding: 15px;

    display: flex;
    align-items: center;
    width: 100%;

    span {
        ${props =>
            props.src
                ? css`
                      background: url(${props.src});
                      background-position: center;
                      background-repeat: no-repeat;
                      background-size: cover;
                      height: 48px;
                      width: 48px;
                      display: block;
                  `
                : ''}
    }

    &:hover {
        span {
            ${props =>
                props.srcHover
                    ? css`
                          background: url(${props.srcHover});
                          background-position: center;
                          background-repeat: no-repeat;
                          background-size: cover;
                          height: 48px;
                          width: 48px;
                          display: block;
                      `
                    : ''}
        }

        i {
            display: inherit;
        }
    }
`;

export const ImageDiv = styled.div`
    min-height: 48px;
    min-width: 48px;
    margin-right: 20px;
`;

export const ButtonDescription = styled.div`
    display: block;
    font-size: 0.875rem;
    color: ${colors.captionGrey};
    font-weight: 400;
`;

export const ButtonText = styled.div`
    display: block;
    width: 100%;
    white-space: nowrap;
    color: ${colors.black};
    font-size: 0.9375rem;

    ${({ leftPadding }) =>
        leftPadding &&
        css`
            padding-left: 68px;
        `}
`;

export const UnorderedList = styled.ul`
    li {
        width: 100%;
    }
`;
