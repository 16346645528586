import get from 'lodash/get';

export function getLabel(headerLink, lng) {
    if (headerLink.title) {
        return headerLink.title;
    }

    if (headerLink.isLanguageSwitcher) {
        return get(
            headerLink.link.find(link => link.value === lng),
            'label',
            'En'
        );
    }

    return '';
}

export function getIconSources(data) {
    return data.header_links.reduce((result, link) => {
        if (link.link.length > 0) {
            const sources = [];

            link.link.forEach(innerLink => {
                if (innerLink.iconDefault) {
                    sources.push(innerLink.iconDefault.publicURL);
                }

                if (innerLink.iconHover) {
                    sources.push(innerLink.iconHover.publicURL);
                }
            });

            return [...result, ...sources];
        }

        return result;
    }, []);
}
